import * as React from "react"
import { Layout, _Section, HeadComponent } from "../components/shared"
import { graphql } from "gatsby"
import { Row, Col } from "react-flexbox-grid"
import { em } from "../styles"
import { _h1 } from "../components/shared/headline.styles"
import { _Blockquote } from "../components/shared/blockquote.styles"
import { prismicGetText, prismicGetRichText, extractPrismicNode } from "../utils/prismicHelper"
import { PageWrapper } from "../utils/PageWrapper"

export const prismicQuery = graphql`
    {
        prismic {
            ...termsFragment
        }
    }
`

interface TermsPageProps {
    data: {
        prismic: {
            allPrivacy_policys: {
                edges: any
            }
        }
    }
}

const TermsPage: React.FC<TermsPageProps> = ({ data }) => {
    // Required check for no data being returned
    const doc = extractPrismicNode(data, "allTerms_of_servicess")
    if (!doc) return null

    return (
        <>
            <HeadComponent title="Terms of Service | Radicle" />
            <PageWrapper>
                <Layout>
                    <_Section>
                        <Row>
                            <Col lgOffset={1} lg={11} md={12}>
                                <_h1 fontSize={40} style={{ marginTop: em(1) }}>
                                    {prismicGetText(doc.title_terms)}
                                </_h1>

                                <_Blockquote>{prismicGetRichText(doc.lead_services)}</_Blockquote>
                            </Col>
                        </Row>
                    </_Section>
                </Layout>
            </PageWrapper>
        </>
    )
}

export default TermsPage
